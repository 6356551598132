export default {
    text: `A. Introdução
A privacidade dos visitantes do nosso site é muito importante para nós, e estamos comprometidos em protegê-la. Esta política explica o que faremos com suas informações pessoais.
Consentir com o uso de cookies de acordo com os termos desta política quando você acessa nosso site pela primeira vez nos permite usar cookies toda vez que você acessa nosso site.

B. Créditos
Este documento foi criado usando um modelo da SEQ Legal (seqlegal.com) modificado pelo Website Planet (www.websiteplanet.com)

C. Coleta de informações pessoais
Os seguintes tipos de informações pessoais podem ser coletados, armazenados e usados:
informações sobre o seu computador, incluindo seu endereço IP, localização geográfica, tipo e versão do navegador e sistema operacional;
informações sobre suas visitas e uso deste site, incluindo fonte de referência, duração da visita, visualizações de página e caminhos de navegação no site;
informações, como seu endereço de e-mail, que você digita quando se registra em nosso site;`
};