<template>
    <v-main>
        <h3 class="mb-4 mt-3" v-if="findByDocumentResult">
            <v-icon>mdi-account-circle-outline</v-icon> {{ findByDocumentResult.name }}
        </h3>
        <section class="row mb-3">
            <v-col lg="3" md="6" v-for="card in cardsStatusData" :key="card.label">
                <CardStatus
                    :label="card.label"
                    :value="card.value"
                    :progress="card.progress"
                    :approvalDate="card.approvalDate"
                    :direction="card.direction"
                />
            </v-col>
        </section>
        <div class="pt-9 pb-9 pr-9 pl-9">
            <v-container>
                <v-row v-if="findByDocumentResult && findByDocumentResult.id != null">
                    <Tabs ref="tabEl" @update="updateTab" :tabs="tabs" :allowUserChange="false" />
                    <Form
                        class="mt-9 form-container"
                        :currentDocument="findByDocumentResult"
                        :typeForm="'saved'"
                    />
                </v-row>

                <v-row class="mt-16">
                    <v-main v-show="currentTab === 'information' && findByDocumentResult">
                        <v-form
                            class="form-request"
                            v-model="valid"
                            sm="6"
                            :validate-on-blur="true"
                            v-show="currentTab === 'information'"
                        >
                            <legend>Solicitação</legend>

                            <v-row>
                                <v-col sm="12" md="4" class="mt-6">
                                    <v-text-field
                                        color="primary"
                                        v-model="form.value"
                                        :rules="[rules.required]"
                                        label="Valor *"
                                        :validate-on-blur="true"
                                        v-mask="currencyMask"
                                        class="v-input-default"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col sm="12">
                                    <label for="credito-extracomments">{{
                                        $tc('credito.request.extraComments')
                                    }}</label>
                                    <textarea
                                        v-model="form.comments"
                                        :rules="[]"
                                        placeholder=""
                                        class="custom-textarea"
                                        id="credito-extracomments"
                                    ></textarea>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col sm="12">
                                    <v-btn
                                        :disabled="!valid"
                                        :title="$tc('defaults.form.next')"
                                        color="secondary"
                                        depressed
                                        class="float-right"
                                        v-on:click="nextTab"
                                    >
                                        {{ $tc('defaults.form.next') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-main>

                    <v-main v-show="currentTab === 'review'">
                        
                        <v-form class="form-custom-disabled mt-0 mb-12">
                            <v-divider></v-divider>
                            <v-row>
                                <v-col sm="12" md="4" class="pt-9 pb-9">
                                    <v-text-field
                                        color="primary"
                                        :value="form.value"
                                        label="Valor"
                                        class="v-input-default"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                        </v-form>
                        <v-form>
                            <legend>Termos e Condições</legend>

                            <v-row>
                                <v-col cols="12" sm="12">
                                    <textarea
                                        class="custom-textarea custom-textarea-terms mt-3"
                                        v-model="termsText"
                                    >
                                    </textarea>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <input
                                        type="checkbox"
                                        v-model="accepTerms"
                                        id="accept-term-ckb"
                                    />
                                    <v-label for="accept-term-ckb" color="secondary"
                                        >Eu concordo com os termos e condições.</v-label
                                    >
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col sm="12">
                                    <v-btn
                                        title="Confirmar"
                                        color="secondary"
                                        class="float-right ml-4 v-btn-confirm"
                                        depressed
                                        :disabled="
                                            !accepTerms ||
                                            requestRequestStatus === RequestStatusEnum.START
                                        "
                                        v-on:click="doRequest"
                                    >
                                        {{ $tc('defaults.form.confirm') }}
                                    </v-btn>
                                    <v-btn
                                        :disabled="
                                            !valid ||
                                            requestRequestStatus === RequestStatusEnum.START
                                        "
                                        title="Anterior"
                                        color="secondary"
                                        class="float-right"
                                        depressed
                                        v-on:click="previousTab"
                                    >
                                        {{ $tc('defaults.form.previous') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-main>
                </v-row>

                <Loader
                    v-if="
                        findByDocumentStatus === RequestStatusEnum.START ||
                        requestRequestStatus === RequestStatusEnum.START
                    "
                    mode="overlay"
                />
            </v-container>
        </div>
    </v-main>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

import Tabs from '@/components/base/Tabs';
import CardStatus from '@/components/base/CardStatus';
import Form from '@/mfes/credito/components/_formSolicitacao';

import { notification } from '@/helpers/notification';
import { RequestStatusEnum } from '@/helpers/consts';
import { currency as currencyMask } from '@/helpers/masks';
import termsText from './_termsText';
import Loader from '@/components/base/Loader';

export default {
    components: {
        Tabs,
        Form,
        CardStatus,
        Loader,
    },
    data() {
        return {
            tabs: [
                {
                    name: 'Informações',
                    path: 'information',
                },
                {
                    name: 'Revisão',
                    path: 'review',
                },
            ],
            currencyMask,
            document: this.$route.params.document,
            valid: false,
            form: {
                value: null,
                comments: null,
            },
            rules: {
                required: value => !!value || 'Campo obrigatório',
            },
            currentTab: 'information',
            termsText: termsText.text,
            accepTerms: false,
            cardsStatusData: [],
            RequestStatusEnum,
        };
    },
    mounted() {
        this.findByDocument(this.document);
    },
    computed: {
        ...mapState('credito', {
            limitsPerRootResult: 'limitsPerRootResult',
            findByDocumentStatus: 'findByDocumentStatus',
            findByDocumentResult: 'findByDocumentResult',
        }),
        ...mapGetters('credito', {
            requestRequestStatus: 'requestRequestStatus',
            selectedProduct: 'selectedProduct',
        }),
    },
    methods: {
        ...mapActions('credito', {
            requestLimit: 'request',
            limitsPerRoot: 'perRoot',
            findByDocument: 'findByDocument',
            dismissRequestRequestStatus: 'dismissRequestRequestStatus',
        }),
        nextTab() {
            this.$refs.tabEl.next();
        },
        previousTab() {
            this.$refs.tabEl.previous();
        },
        updateTab(data) {
            this.currentTab = data.path;
        },
        doRequest() {
            this.requestLimit({
                productId: this.selectedProduct.id,
                data: this.findByDocumentResult,
                value: this.form.value,
                comments: this.form.comments,
            });
        },
    },
    watch: {
        findByDocumentResult(newValue) {
            if (newValue.configuracoes) {
                const c = newValue.configuracoes.find(
                    item => item.idProduto === this.selectedProduct.id
                );
                if (c) {
                    newValue.segment = c.segment;
                }
            }
            this.limitsPerRoot({
                idProduct: this.selectedProduct.id,
                identifier: this.findByDocumentResult.root,
            });
        },
        findByDocumentStatus(newValue) {
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    'Desculpe-nos, ocorreu um erro ao buscar pelo documento: ' + newValue.message
                );
                if (newValue.httpStatus === 404) {
                    this.$router.push(`/admin/credito/solicitacao`);
                    this.dismissRequestRequestStatus();
                }
                return;
            }
        },
        requestRequestStatus(newValue) {
            if (newValue === RequestStatusEnum.SUCCESS) {
                notification(this.$dialog.notify.success, 'Solicitação efetuada com sucesso');
                this.dismissRequestRequestStatus();
                this.$router.push(`/admin/credito/limites/consultar`);
                return RequestStatusEnum.SUCCESS;
            }
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    'Desculpe-nos, ocorreu um erro ao solicitar o crédito: ' + newValue.message
                );
                return RequestStatusEnum.ERROR;
            }
        },
        limitsPerRootResult(newValue) {
            if (!newValue || !Array.isArray(newValue) || newValue.length === 0) {
                return;
            }
            this.cardsStatusData = [
                newValue[0].approved,
                newValue[0].movement,
                newValue[0].used,
                newValue[0].available,
            ];
        },
    },
};
</script>
<style scoped lang="scss">
@import './SolicitarValor.scss';
</style>