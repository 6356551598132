<template>
    <article>
        <h4>
            <v-icon>mdi-tag-plus-outline</v-icon>
            {{ label }}
        </h4>

        <span>{{ formatarDinheiro(value) }}</span>

        <v-row v-if="approvalDate">
            <v-col>
                <h5>Data de Decisão</h5>
            {{ formatarData(approvalDate) }}
            </v-col>
        </v-row>

        <v-row align="center" v-if="progress">
            <v-col md="6" lg="4">
                <span :class="{ isdown: direction == 'DOWN' }">{{ progress }}%</span>
                <v-icon class="arrow" v-if="direction == 'UP'" color="secondary"
                    >mdi-arrow-up</v-icon
                >
                <v-icon class="arrow" v-if="direction == 'DOWN'" color="error"
                    >mdi-arrow-down</v-icon
                >
            </v-col>
            <v-col md="6" lg="8">
                <v-progress-linear :value="progress" color="info"></v-progress-linear>
            </v-col>
        </v-row>
    </article>
</template>

<script>

import { formatarDinheiro } from '@/helpers/formatarDinheiro';
import { formatarData } from '@/helpers/data';
import { RequestStatusEnum } from '@/helpers/consts';
export default {
    props: {
        label: {
            type: String,
        },
        value: {
            type: Number,
        },
        progress: {
            type: Number,
        },
        direction: {
            type: String,
            default: RequestStatusEnum.IDLE,
        },
        approvalDate: {
            type: String
        }
    },
    methods: {
        formatarDinheiro,
        formatarData
    },
};
</script>
<style scoped lang="scss">
article {
    background: white;
    border-radius: 0.25rem;
    padding: 1rem;
    color: var(--v-primary-darken1);
    font-weight: normal;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h4 {
        font-weight: normal;
        margin-bottom: 1rem;
        .v-icon {
            background-color: var(--v-primary-lighten3);
            font-size: 1.25rem;
            padding: 0.75rem;
            border-radius: 0.25rem;
            font-weight: bold;
            color: var(--v-info-darken1);
            margin-right: 0.5rem;
        }
    }

    span {
        font-size: 1.5rem;
    }
    .row:last-child {
        margin-top: 0.25rem;
        span {
            font-size: 0.75rem;
            color: var(--v-secondary-base);
            &.isdown {
                color: var(--v-error-base);
            }
        }
    }
    .v-icon.arrow {
        font-size: 1rem;
        margin-left: 0.25rem;
    }

    .date-container {
        h5 {
            font-weight: normal;
        }
    }
}
</style>
